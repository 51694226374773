<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Surveys</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$privilege.hasPrivilege('SURVEY_CREATE')"
        fab
        small
        :to="{
          name: 'survey',
          params: { id: 0 }
        }"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table :items="items" :headers="headers">
        <template v-slot:item.surveyKey="{ item }"
          ><router-link :to="{ name: 'survey', params: { id: item.id } }">{{ item.surveyKey }}</router-link></template
        >
        <template v-slot:item.effectiveDate="{ item }">
          {{ item.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
        <template v-slot:item.expirationDate="{ item }">
          {{ item.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Surveys",
  metaInfo: {
    title: "Surveys"
  },
  data() {
    return {
      items: [],
      headers: [
        {
          value: "surveyKey",
          text: "SurveyKey",
          sortable: true
        },
        {
          value: "name",
          text: "Name",
          sortable: true
        },
        {
          value: "description",
          text: "Description",
          sortable: true
        },
        { value: "effectiveDate", text: "Effective Date", sortable: false },
        { value: "expirationDate", text: "Expiration Date", sortable: false }
      ]
    };
  },
  methods: {
    fetchData() {
      this.$api.post("/api/surveys/search", {}).then(({ data }) => {
        this.items = data.content;
      });
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Surveys"),
        exact: true
      }
    ]);
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedClient"])
  }
};
</script>
